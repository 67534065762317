<template>
    <div class="wrapper">
        <CRow>
            <CCol lg="5">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="window-maximize"/> Create New Form Data</CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol lg="12">
                                Select Form
                            </CCol>
                            <CCol lg="12">
                                <v-select 
                                    placeholder="Select one."
                                    label="name"
                                    :options="forms"
                                    v-model="drpdwn_form"
                                />
                            </CCol>
                        </CRow>

                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow v-if="form">
            <CCol lg="12">
                <CCardHeader><font-awesome-icon icon="pencil-alt"/> Fill-Out {{form.name}}</CCardHeader>
                <CCard>
                    <CCardBody :key="form_data_key">
                        <span v-for="(usageElement, usageIndex) in header_fields" v-bind:key="usageIndex">
                            <CRow v-for="(rowElement, rowElementIndex) in usageElement" v-bind:key="rowElementIndex">
                                <template v-for="(singleElement, singleElementIndex) in rowElement" >
                                    <CCol v-bind:key="singleElementIndex">
                                        <template v-if="singleElement.field_id">
                                            <template v-if="singleElement.field.field_type.name == 'label'">
                                                <div :style="`white-space: pre-line; text-align:${singleElement.field.label_format}` ">{{singleElement.field.label_value}}</div>
                                                <br>
                                            </template>
                                            <template  v-if="singleElement.field.field_type.name == 'text' 
                                                            || singleElement.field.field_type.name == 'number' 
                                                                || singleElement.field.field_type.name == 'number_format' 
                                                                    || singleElement.field.field_type.name == 'date'">
                                                <CInput 
                                                    :readonly="singleElement.readonly == '0' ? false : true"
                                                    v-if="singleElement.field.field_type.name == 'text'"
                                                    :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name"
                                                    :type="singleElement.field.field_type.name" 
                                                    :placeholder="`Enter ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                    autocomplete="off" 
                                                    :class="singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-inp-error' : 'has-inp-success' : ''"
                                                    v-model="header_footer_field_values[singleElement.id]"
                                                />
                                                <CInput 
                                                    :readonly="singleElement.readonly == '0' ? false : true"
                                                    :class="singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-inp-error' : 'has-inp-success' : ''"
                                                    v-if="singleElement.field.field_type.name == 'number' || singleElement.field.field_type.name == 'number_format'"
                                                    :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name"
                                                    v-numberonly
                                                    ondrop="return false;" 
                                                    :placeholder="`Enter ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                    autocomplete="off" 
                                                    v-model="header_footer_field_values[singleElement.id]"
                                                />
                                                <label v-if="singleElement.field.field_type.name == 'date'">{{singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}}</label>
                                                <vc-date-picker
                                                    v-model="header_footer_field_values[singleElement.id]"
                                                    v-if="singleElement.field.field_type.name == 'date'"
                                                    mode="date"
                                                    :masks="masks"
                                                    :class="singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-inp-error' : 'has-inp-success' : ''"
                                                >
                                                    <template v-slot="{ inputValue, inputEvents  }">
                                                        <CRow>
                                                            <CCol lg="12">
                                                                <CInput
                                                                    :disabled="singleElement.readonly == '0' ? false : true"
                                                                    class="field-read-only"
                                                                    readonly
                                                                    :placeholder="`Select ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                                    :value="inputValue"
                                                                    v-on="inputEvents"
                                                                >
                                                                    <template #append>
                                                                        <span v-if="!singleElement.validation">
                                                                            <CButton 
                                                                                title="Clear Date"
                                                                                color="danger"
                                                                                :disabled="!inputValue"
                                                                                @click="header_footer_field_values[singleElement.id] = null"
                                                                            >
                                                                                <font-awesome-icon icon="times"/>
                                                                            </CButton>
                                                                        </span>
                                                                    </template>
                                                                </CInput>
                                                            </CCol>
                                                        </CRow>
                                                    </template>
                                                </vc-date-picker>
                                            </template>
                                            <CTextarea
                                                :readonly="singleElement.readonly == '0' ? false : true"
                                                v-if="singleElement.field.field_type.name == 'textarea'"
                                                :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name"
                                                :placeholder="`Enter brief ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                autocomplete="off"  
                                                :class="singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-txtarea-error grp-text-area' : 'has-txtarea-success grp-text-area' : 'grp-text-area'"
                                                v-model="header_footer_field_values[singleElement.id]"
                                            />
                                            <template>
                                                <CInput 
                                                    :key="input_search_key"
                                                    :class="singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-inp-error' : 'has-inp-success' : ''"
                                                    :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name" 
                                                    v-if="singleElement.field.field_type.name == 'input_search'"
                                                    :placeholder="`Please select ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                    type="text" 
                                                    autocomplete="off" 
                                                    readonly 
                                                    v-model="header_footer_field_values[singleElement.id]"
                                                >
                                                    <template #append>
                                                        <CButton color="primary" 
                                                            title="Search"
                                                            @click="inputSearch(singleElement, 'header')"
                                                        >
                                                        <font-awesome-icon icon="search"/>
                                                        </CButton>
                                                        <CButton color="danger" 
                                                            title="Clear"
                                                            @click="inputClear(singleElement, 'header')"
                                                        >
                                                        <font-awesome-icon icon="times"/>
                                                        </CButton>
                                                    </template>
                                                </CInput>
                                                <v-select 
                                                    :readonly="singleElement.readonly == '0' ? false : true"
                                                    :class="singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-error' : 'has-success' : ''"
                                                    v-if="singleElement.field.field_type.name == 'dropdown'"
                                                    :placeholder="`Please select ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                    :options="singleElement.field.field_drop_down_values_data"
                                                    label="value"
                                                    :reduce="value => value.value" 
                                                    v-model="header_footer_field_values[singleElement.id]"
                                                >
                                                    <template #header>
                                                        <div style="padding-bottom:9px;">{{singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}}</div>
                                                    </template>
                                                </v-select>
                                                <br v-if="singleElement.field.field_type.name == 'dropdown' || singleElement.field.field_type.name == 'input_search'">
                                            </template>
                                        </template>
                                        <template v-if="singleElement.aggregate_function">
                                            <span><small><font-awesome-icon icon="info-circle" style="color: #4caf50;"/> {{singleElement.aggregate_function[0].toUpperCase() + singleElement.aggregate_function.slice(1).toLowerCase()}} of {{singleElement.aggregate_column_field ? singleElement.aggregate_column_field.display_name : null}} will be computed upon saving of the form.</small></span>
                                            <CInput 
                                                readonly
                                                type="text" 
                                                :placeholder="`${singleElement.aggregate_function[0].toUpperCase() + singleElement.aggregate_function.slice(1).toLowerCase()} of ${singleElement.aggregate_column_field ? singleElement.aggregate_column_field.display_name : null}`" 
                                                autocomplete="off" 
                                            />
                                        </template>
                                    </CCol>
                                </template>
                            </CRow>
                        </span>
                        <template v-if="detail_fields.length > 0"> <!-- this is for lines -->
                            <hr>
                            <CRow>
                                <CCol lg="12">
                                    <CRow v-for="index in details_row" v-bind:key="index">
                                            <CCol lg="11">
                                            <CRow>
                                                <CCol v-for="(detailElement, detailIndex) in detail_fields" v-bind:key="detailIndex">
                                                <template v-if="detailElement.field_id">
                                                    
                                                    <template v-if="detailElement.field.field_type.name == 'label'">
                                                        <div :style="`white-space: pre-line; text-align:${detailElement.field.label_format}` ">{{detailElement.field.label_value}}</div>
                                                        <br>
                                                    </template>
                                                    <template  v-if="detailElement.field.field_type.name == 'text' 
                                                                    || detailElement.field.field_type.name == 'number'
                                                                        || detailElement.field.field_type.name == 'number_format' 
                                                                            || detailElement.field.field_type.name == 'date'">
                                                        <CInput 
                                                            :readonly="detailElement.readonly == '0' ? false : true"
                                                            :class="detailElement.validation && detailElement.validation.split('|')[0] == 'required' ? 
                                                                    !detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id] 
                                                                        ? 'has-inp-error' : 'has-inp-success' : ''"
                                                            v-if="detailElement.field.field_type.name == 'text'"
                                                            :label="detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name"
                                                            :type="detailElement.field.field_type.name" 
                                                            :placeholder="`Enter ${detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}`" 
                                                            autocomplete="off" 
                                                            v-model="detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id]"
                                                        />
                                                        <CInput 
                                                            :readonly="detailElement.readonly == '0' ? false : true"
                                                            :class="detailElement.validation && detailElement.validation.split('|')[0] == 'required' ? !detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id] ? 'has-inp-error' : 'has-inp-success' : ''"
                                                            v-if="detailElement.field.field_type.name == 'number' || detailElement.field.field_type.name == 'number_format'"
                                                            :label="detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name"
                                                            v-numberonly
                                                            ondrop="return false;" 
                                                            :placeholder="`Enter ${detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}`" 
                                                            autocomplete="off" 
                                                            v-model="detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id]"
                                                        />
                                                        <label v-if="detailElement.field.field_type.name == 'date'">{{detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}}</label>
                                                        <vc-date-picker
                                                            :class="detailElement.validation && detailElement.validation.split('|')[0] == 'required' ? !detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id] ? 'has-inp-error' : 'has-inp-success' : ''"
                                                            v-model="detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id]"
                                                            v-if="detailElement.field.field_type.name == 'date'"
                                                            mode="date"
                                                            :masks="masks"
                                                        >
                                                            <template v-slot="{ inputValue, inputEvents  }">
                                                                <CRow>
                                                                    <CCol lg="12">
                                                                        <CInput
                                                                            :disabled="detailElement.readonly == '0' ? false : true"
                                                                            class="field-read-only"
                                                                            readonly
                                                                            :placeholder="`Select ${detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}`" 
                                                                            :value="inputValue"
                                                                            v-on="inputEvents"
                                                                        >
                                                                            <template #append>
                                                                                <span v-if="!detailElement.validation">
                                                                                    <CButton 
                                                                                        title="Clear Date"
                                                                                        color="danger"
                                                                                        :disabled="!inputValue"
                                                                                        @click="detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id] = null"
                                                                                    >
                                                                                        <font-awesome-icon icon="times"/>
                                                                                    </CButton>
                                                                                </span>
                                                                            </template>
                                                                        </CInput>
                                                                    </CCol>
                                                                </CRow>
                                                            </template>
                                                        </vc-date-picker>
                                                    </template>
                                                    <CTextarea
                                                        :readonly="detailElement.readonly == '0' ? false : true"
                                                        :label="detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name"
                                                        v-if="detailElement.field.field_type.name == 'textarea'"
                                                        :placeholder="`Enter brief ${detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}`" 
                                                        autocomplete="off"  
                                                        :class="detailElement.validation && detailElement.validation.split('|')[0] == 'required' ? !detail_field_values['line_'+index+'#fieldid_'+detailElement.id] ? 'has-txtarea-error grp-text-area' : 'has-txtarea-success grp-text-area' : 'grp-text-area'"
                                                        v-model="detail_field_values['line_'+index+'#fieldid_'+detailElement.id]"
                                                    />
                                                    <template>
                                                        <CInput 
                                                            :key="input_search_key"
                                                            :class="detailElement.validation && detailElement.validation.split('|')[0] == 'required' ? !detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id] ? 'has-inp-error' : 'has-inp-success' : ''"
                                                            :label="detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name" 
                                                            v-if="detailElement.field.field_type.name == 'input_search'"
                                                            :placeholder="`Please select ${detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}`" 
                                                            type="text" 
                                                            autocomplete="off" 
                                                            readonly 
                                                            v-model="detail_field_values['line_'+index+'#fieldid_'+detailElement.id]"
                                                        >
                                                            <template #append>
                                                                <CButton color="primary" 
                                                                    title="Search"
                                                                    @click="inputSearch(detailElement, 'detail', index)"
                                                                >
                                                                <font-awesome-icon icon="search"/>
                                                                </CButton>
                                                                <CButton color="danger" 
                                                                    title="Clear"
                                                                    @click="inputClear(detailElement, 'detail', index)"
                                                                >
                                                                <font-awesome-icon icon="times"/>
                                                                </CButton>
                                                            </template>
                                                        </CInput>
                                                        <v-select 
                                                            :readonly="detailElement.readonly == '0' ? false : true"
                                                            :class="detailElement.validation && detailElement.validation.split('|')[0] == 'required' ? !detail_field_values['line_'+index+'#fieldid_'+detailElement.id] ? 'has-error' : 'has-success' : ''"
                                                            v-if="detailElement.field.field_type.name == 'dropdown'"
                                                            :placeholder="`Please select ${detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}`" 
                                                            :options="detailElement.field.field_drop_down_values_data"
                                                            label="value"
                                                            :reduce="value => value.value" 
                                                            v-model="detail_field_values['line_'+index+'#fieldid_'+detailElement.id]"
                                                        >
                                                            <template #header>
                                                                <div style="padding-bottom:9px;">{{detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}}</div>
                                                            </template>
                                                        </v-select>
                                                    <br v-if="detailElement.field.field_type.name == 'dropdown' || detailElement.field.field_type.name == 'input_search'">
                                                    </template>
                                                </template>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                        
                                        <CCol lg="1">
                                            <CButton 
                                                size="sm"
                                                title="Remove row"
                                                type="submit" 
                                                shape="pill" 
                                                color="danger"
                                                style="margin-top:50%;"
                                                @click="removeFormDetailFieldElement(index, $event)"
                                            >
                                                <font-awesome-icon icon="times"/>
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                    <CButton 
                                        size="sm"
                                        class="float-right"
                                        shape="pill" 
                                        color="info"
                                        @click="details_row++"
                                    >
                                        <font-awesome-icon icon="plus"/> Add Row
                                    </CButton>
                                </CCol>
                            </CRow>
                        </template>  <!-- end lines -->
                        <hr>
                        <span v-for="(usageElement, usageIndex) in footer_fields" v-bind:key="usageIndex">
                            <CRow v-for="(rowElement, rowElementIndex) in usageElement" v-bind:key="rowElementIndex">
                                <template v-for="(singleElement, singleElementIndex) in rowElement" >
                                    <CCol v-bind:key="singleElementIndex">
                                        <template v-if="singleElement.field_id">
                                            <template v-if="singleElement.field.field_type.name == 'label'">
                                                <div :style="`white-space: pre-line; text-align:${singleElement.field.label_format}` ">{{singleElement.field.label_value}}</div>
                                                <br>
                                            </template>
                                            <template  v-if="singleElement.field.field_type.name == 'text' 
                                                        || singleElement.field.field_type.name == 'number' 
                                                            || singleElement.field.field_type.name == 'number_format' 
                                                                || singleElement.field.field_type.name == 'date'">
                                                <CInput 
                                                    :readonly="singleElement.readonly == '0' ? false : true"
                                                    :class="singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-inp-error' : 'has-inp-success' : ''"
                                                    v-if="singleElement.field.field_type.name == 'text'"
                                                    :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name"
                                                    :type="singleElement.field.field_type.name" 
                                                    :placeholder="`Enter ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                    autocomplete="off" 
                                                    v-model="header_footer_field_values[singleElement.id]"
                                                />
                                                <CInput 
                                                    :readonly="singleElement.readonly == '0' ? false : true"
                                                    :class="singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-inp-error' : 'has-inp-success' : ''"
                                                    v-if="singleElement.field.field_type.name == 'number' || singleElement.field.field_type.name == 'number_format'"
                                                    :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name"
                                                    v-numberonly
                                                    ondrop="return false;" 
                                                    :placeholder="`Enter ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                    autocomplete="off" 
                                                    v-model="header_footer_field_values[singleElement.id]"
                                                />
                                                <label v-if="singleElement.field.field_type.name == 'date'">{{singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}}</label>
                                                <vc-date-picker
                                                    v-model="header_footer_field_values[singleElement.id]"
                                                    v-if="singleElement.field.field_type.name == 'date'"
                                                    mode="date"
                                                    :masks="masks"
                                                    :class="singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-inp-error' : 'has-inp-success' : ''"
                                                >
                                                    <template v-slot="{ inputValue, inputEvents   }">
                                                        <CRow>
                                                            <CCol lg="12">
                                                                <CInput
                                                                    :disabled="singleElement.readonly == '0' ? false : true"
                                                                    class="field-read-only"
                                                                    readonly
                                                                    :placeholder="`Select ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                                    :value="inputValue"
                                                                    v-on="inputEvents "
                                                                >
                                                                    <template #append>
                                                                        <span v-if="!singleElement.validation">
                                                                            <CButton 
                                                                                title="Clear Date"
                                                                                color="danger"
                                                                                :disabled="!inputValue"
                                                                                @click="header_footer_field_values[singleElement.id] = null"
                                                                            >
                                                                                <font-awesome-icon icon="times"/>
                                                                            </CButton>
                                                                        </span>
                                                                    </template>
                                                                </CInput>
                                                            </CCol>
                                                        </CRow>
                                                    </template>
                                                </vc-date-picker>
                                            </template>
                                            <CTextarea
                                                :readonly="singleElement.readonly == '0' ? false : true"
                                                :class="singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-txtarea-error grp-text-area' : 'has-txtarea-success grp-text-area' : 'grp-text-area'"
                                                v-if="singleElement.field.field_type.name == 'textarea'"
                                                :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name"
                                                :placeholder="`Enter brief ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                autocomplete="off"  
                                                v-model="header_footer_field_values[singleElement.id]"
                                            />
                                            <template>
                                                <CInput 
                                                    :key="input_search_key"
                                                    :class="singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-inp-error' : 'has-inp-success' : ''"
                                                    :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name" 
                                                    v-if="singleElement.field.field_type.name == 'input_search'"
                                                    :placeholder="`Please select ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                    type="text" 
                                                    autocomplete="off" 
                                                    readonly 
                                                    v-model="header_footer_field_values[singleElement.id]"
                                                >
                                                    <template #append>
                                                        <CButton color="primary" 
                                                            title="Search"
                                                            @click="inputSearch(singleElement, 'footer')"
                                                        >
                                                        <font-awesome-icon icon="search"/>
                                                        </CButton>
                                                        <CButton color="danger" 
                                                            title="Clear"
                                                            @click="inputClear(singleElement, 'footer')"
                                                        >
                                                        <font-awesome-icon icon="times"/>
                                                        </CButton>
                                                    </template>
                                                </CInput>
                                                <v-select 
                                                    :readonly="singleElement.readonly == '0' ? false : true"
                                                    :class="singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-error' : 'has-success' : ''"
                                                    v-if="singleElement.field.field_type.name == 'dropdown'"
                                                    :placeholder="`Please select ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                    :options="singleElement.field.field_drop_down_values_data"
                                                    label="value"
                                                    :reduce="value => value.value" 
                                                    v-model="header_footer_field_values[singleElement.id]"
                                                >
                                                    <template #header>
                                                        <div style="padding-bottom:9px;">{{singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}}</div>
                                                    </template>
                                                </v-select>
                                            <br v-if="singleElement.field.field_type.name == 'dropdown' || singleElement.field.field_type.name == 'input_search'">
                                            </template>
                                        </template>
                                        <template v-if="singleElement.aggregate_function">
                                            <span><small><font-awesome-icon icon="info-circle" style="color: #4caf50;"/> {{singleElement.aggregate_function[0].toUpperCase() + singleElement.aggregate_function.slice(1).toLowerCase()}} of {{singleElement.aggregate_column_field ? singleElement.aggregate_column_field.display_name : null}} will be computed upon saving of the form.</small></span>
                                            <CInput 
                                                readonly
                                                type="text" 
                                                :placeholder="`${singleElement.aggregate_function[0].toUpperCase() + singleElement.aggregate_function.slice(1).toLowerCase()} of ${singleElement.aggregate_column_field ? singleElement.aggregate_column_field.display_name : null}`" 
                                                autocomplete="off" 
                                            />
                                        </template>
                                    </CCol>
                                </template>
                            </CRow>
                        </span>
                        <br>
                        <CRow>
                            <CCol lg="12">
                                <CButton 
                                    class="float-right"
                                    type="submit" 
                                    shape="pill" 
                                    color="primary"
                                    @click="saveFormData()"
                                >
                                    <font-awesome-icon icon="save"/> Save Form Data
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        
        <CModal
            :show.sync="large_list_modal"
            :closeOnBackdrop="false"
            color="primary"
            size="lg"
            v-if="large_list_selected_element"
            >
            <template #header>
                <h6> <font-awesome-icon icon="list"/> {{ large_list_selected_element.field.display_name}} </h6>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="large_list_modal = false"
                >
                    <font-awesome-icon  size="sm" icon="times"/>
                </CButton>
            </template>
            <template #footer>
                <hr>
            </template>
            <template #body-wrapper>
                <CCardBody>
                    <CRow>
                        <CCol lg="6">
                            <CInput 
                                type="text" 
                                placeholder="Search" 
                                autocomplete="off" 
                                v-model="large_list_search"
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="12">
                            <CDataTable
                                :key="large_list_modal_key"
                                :items="large_list_items"
                                :fields="large_list_field"
                                border
                                sorter
                                @row-clicked="largeListSelectedItem"
                                pagination
                            >
                                <template #action="{item, index}">
                                    <td>
                                        <center>
                                            <CButton 
                                                color="danger" 
                                                @click="removeSignatoryRequirement(item, index)" 
                                                shape="pill" 
                                                size="sm"
                                            >
                                                <font-awesome-icon icon="times"/> Remove
                                            </CButton>
                                        </center>
                                    </td>
                                </template>
                            </CDataTable>
                        </CCol>
                    </CRow>
                </CCardBody>
            </template>
        </CModal>
    </div>
</template>
<script>
import FormManagementJS from '../js/form-management.js';
export default {
    name: 'FDCreate',
    data() {
        return {
            input_search_key:0,
            large_list_search: '',
            large_list_modal: false,
            large_list_modal_key: 0,
            large_list_selected_element:  null,
            large_list_selected_element_index:  null,
            large_list_selected_element_custom_usage:  null,

            large_list: [],
            large_list_field: [
                { key: 'id'},
                { key: 'value' },
            ],


            form_data_key:0,
            drpdwn_form: '',
            forms:[],
            header_fields: [],
            footer_fields: [],
            detail_fields: [],
            form: null,
            details_row: 1,

            form_detail_fields: [],

            reserve_details_field_row: null,   // storage of 1 static row for details field. Maybe using for adding of new rows dynamically.

            header_footer_field_values: {},
            detail_field_values: {},
            loaded: false,
            
            masks: {
                input: 'DD-MMMM-YYYY',
            },

            af_and_columns: [],
        }

    },
    created() {
        this.$emit('activeTab', 1);
        this.getForms();
    },
    computed: {
        large_list_items: function () {
            return this.large_list.filter((e) => e.value.toLowerCase().indexOf(this.large_list_search.toLowerCase()) > -1 || e.id.toString().toLowerCase().indexOf(this.large_list_search.toLowerCase()) > -1);
        }
    },
    methods: {
        inputClear: function (element, custom_usage, index = null) {
            this.input_search_key++;
            if(custom_usage != 'detail') {
                this.header_footer_field_values[element.id] = ''
            } else {
                this.detail_field_values['line_'+index+'#fieldid_'+element.id] = ''
            }
        },
        inputSearch: function (element, custom_usage, index = null) {
            this.large_list_selected_element = element;
            this.large_list_selected_element_index = index;
            this.large_list_selected_element_custom_usage = custom_usage;
            this.large_list = this.large_list_selected_element.field.field_drop_down_values_data;
            this.large_list_modal = true;
        },
        largeListSelectedItem: function (item, index, column, event) {
            if(this.large_list_selected_element_custom_usage == 'header' || this.large_list_selected_element_custom_usage == 'footer') {
                this.header_footer_field_values[this.large_list_selected_element.id] = item.value
            } else {
                this.detail_field_values['line_'+this.large_list_selected_element_index+'#fieldid_'+this.large_list_selected_element.id] = item.value
            }
            this.large_list_modal = false;
        },
        validateFields: function () {
            let required_fields = []
            for (let i = 0; i < this.form.form_header_footer_fields.length; i++) {
                const element = this.form.form_header_footer_fields[i];
                if(element.validation && element.validation.split('|')[0] == 'required' && !this.header_footer_field_values[element.id]) {
                    required_fields.push(element.field.display_name);
                }
            }

            if( required_fields.length > 0 ) {
                return false
            }
            return true;
        },
        getForms: function () {
            this.$Progress.start()
            axios.get('/form-managements/form/active-list', {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    this.forms = response.data.data
                    this.$Progress.finish()
                }
            })
        },
        getFormDetails: function (form_id) {
            this.$Progress.start()
            axios.get('/form-managements/form/show/'+form_id, {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    this.form = response.data.data
                    this.assignData(response.data.data)
                    this.$Progress.finish()
                }
            })
        },
        assignData: function (data) {
            for (let i = 0; i < data.form_header_footer_fields.length; i++) {
                const element = data.form_header_footer_fields[i];
                if(element.aggregate_function && element.aggregate_column_field_id) {
                    let row = {
                        fhfi_id: element.id,
                        aggregate_function: element.aggregate_function,
                        aggregate_column_field_id: parseInt(element.aggregate_column_field_id),
                    }
                    this.af_and_columns.push(row);
                }
            }

            let details = FormManagementJS.sortDetailFields(data.form_detail_fields);

            this.header_fields = FormManagementJS.generateHeaderFooterField(data.form_header_footer_fields, 'header', true);
            this.detail_fields = FormManagementJS.generateDetailField(details, true);
            this.footer_fields = FormManagementJS.generateHeaderFooterField(data.form_header_footer_fields, 'footer', true);

            Object.assign(this.header_footer_field_values, FormManagementJS.generateDefaultValueToField(data.form_header_footer_fields));
            // Object.assign(this.detail_field_values, FormManagementJS.generateDefaultValueToField(data.form_detail_fields));
        },
        removeFormDetailFieldElement: function (index, event) {
            event.target.closest('.row').remove();
            if(Object.entries(this.detail_field_values).length > 0) {
                for (let i = 0; i < this.form.form_detail_fields.length; i++) {
                    const element = this.form.form_detail_fields[i];
                    let key = 'line_'+index+'#fieldid_'+element.id;
                    delete this.detail_field_values[key]
                }
            }
        },
        saveFormData: function () {
            // console.log(this.header_footer_field_values);
            // return;

            if(!this.validateFields()) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: `Please fill-up the required fields.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
            }
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to save the data of <b>${this.form.name}</b>.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 

                    let hf_field_data = [];
                    let d_field_data = []
                    let lineNumber = 0;
                    let lineHolder=0;
                    let array_ = [];

                    // Data Manipulation-----------
                    
                    // Header / Footer
                    for (var key of Object.keys(this.header_footer_field_values)) {
                        let element = {
                            fhff_id: key,   //form header footer field id 
                            // is_reference_number: parseInt(this.form.form_header_footer_fields.find(item => item.id === parseInt(key)).is_reference), //is reference or not
                            parameter_name: this.form.form_header_footer_fields.find(item => item.id === parseInt(key)).field.parameter_name,
                            // validation: this.form.form_header_footer_fields.find(item => item.id === parseInt(key)).validation,
                        }
                        if(this.form.form_header_footer_fields.find(item => item.id === parseInt(key)).field.field_type.name == 'date') {
                            element.value = this.header_footer_field_values[key].toISOString().split('T')[0]
                        } else {
                            element.value = this.header_footer_field_values[key]
                        }

                        hf_field_data.push(element);
                    }

                    // // Adding required field that is null to array 
                    // for (let i = 0; i < this.form.form_header_footer_fields.length; i++) {
                    //     const element = this.form.form_header_footer_fields[i];
                    //     if((element.validation && element.validation.includes('required'))
                    //         && !hf_field_data.some((item) => { return parseInt(item.fhff_id) === parseInt(element.id) })) {
                    //         let required_element_that_is_null = {
                    //             fhff_id: element.id.toString(),
                    //             parameter_name: element.field.parameter_name,
                    //             validation: element.validation,
                    //             value: '',
                    //         }
                    //         hf_field_data.push(required_element_that_is_null);
                    //     }
                    // }
                    // end adding
                    // End Header / Footer


                    // Details or Lines
                    for (var key in this.detail_field_values) {
                        array_.push(key)
                    }
                    array_.sort(function(a, b){return a.localeCompare(b, 'en', { numeric: true })});
                    for (var key in array_) {
                        let str = array_[key].split("#");
                        if(lineHolder != parseInt(str[0].split('_')[1])) {
                            lineNumber++
                        }
                        let row = {
                            fdf_id: str[1].split('_')[1], //field_id
                            line:  lineNumber, //row number = line *line starts in 1 
                            parameter_name: this.form.form_detail_fields.find(item => item.id === parseInt(str[1].split('_')[1])).field.parameter_name,
                            // validation:  this.form.form_detail_fields.find(item => item.id === parseInt(str[1].split('_')[1])).validation
                        }

                        if(this.form.form_detail_fields.find(item => item.id === parseInt(str[1].split('_')[1])).field.field_type.name == 'date') {
                            row.value = this.detail_field_values[array_[key]].toISOString().split('T')[0]
                        } else {
                            row.value = this.detail_field_values[array_[key]] ? this.detail_field_values[array_[key]] : null
                        }
                       

                        d_field_data.push(row);
                        lineHolder = parseInt(str[0].split('_')[1])
                    }
                    // End details or lines
                    
                    let data = {
                        form_id: this.form.id,
                        header_footer_field_data: hf_field_data,
                        detail_field_data: d_field_data,
                    }



                    if(this.af_and_columns.length >= 0) {
                        data.af_and_columns = this.af_and_columns;
                    }

                    this.$Progress.start()
                    return axios.post('/form-managements/form-data/store', data , {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.form.name}</b> has been created.`,
                            })
                            this.form_data_key++;

                            this.header_footer_field_values={},
                            this.detail_field_values={},

                            this.$Progress.finish()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })

        },
    },
    watch: {
        drpdwn_form: function (value) {
            this.form = null;
            // this.detail_field_values = {};
            // this.header_footer_field_values = {};
            if(value) this.getFormDetails(value.id)
        },
        large_list_modal: function (value) {
            if(!value) {
                this.large_list_selected_element = null;
                this.large_list_selected_element_index = null,
                this.large_list_selected_element_custom_usage = null;
                this.large_list_search = '';
            }
        }
    }
}
</script>